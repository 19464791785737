<template>
  <div class="width100 page-home-index">
    <div class="background-color10">
      <div
        v-if="$store.state.profile.companyId === 0"
        class="content-box margin-auto flex align-items-center padding2 margin-top3 warning-box fontSize14 border-radius1"
      >
        <img src="@/assets/home/warning.png" alt="warning" />
        <span class="margin-left2"
          >认证为企业用户即可入驻平台,入驻后可在管理中心上传产品库存价格!</span
        >
        <span class="margin-left2 cursor-pointer textColor23" @click="auth">
          前往认证 >
        </span>
      </div>
      <div class="content-box margin-auto flex margin-top3 margin-bottom3">
        <div>
          <img
            class="cursor-pointer logo-img"
            src="@/assets/home/logo@3x.png"
            alt="logo"
            @click="redirect('/home')"
          />
        </div>
        <div class="flex1 margin-left6">
          <div
            @click="gotoSearch"
            class="flex flex-center search-box border-radius6 overflow-hidden background-color27"
          >
            <div
              class="flex flex-center background-color26 padding-left3 padding-right3 padding-top2 padding-bottom2 fontSize14"
              style="margin-left: 2px; border-radius: 20px 0 0 20px;"
            >
              <span>查商品</span>
            </div>
            <input
              type="text"
              v-model="keyword"
              placeholder="输入配件名称、OE号、品牌、车型等关键字查询"
              class="search-box-input flex1 padding-left4 padding-right4 padding-top2 padding-bottom2 fontSize14"
            />
            <div
              class="flex flex-center background-color27 padding-left4 padding-right4 padding-top2 padding-bottom2 fontSize16 textColor10 cursor-pointer"
            >
              <span>搜索</span>
              <img
                class="margin-left1 search-img"
                src="@/assets/home/search.png"
                alt="search"
              />
            </div>
          </div>
        </div>
        <div class="margin-left6">
          <div
            id="scrollerWrapper"
            class="fontSize12 overflow-y-auto"
            style="height: 60px;"
          >
            <div id="scroller">
              <p v-for="(item, index) in dynamicList" :key="index">
                {{ item.content }}
              </p>
            </div>
          </div>
        </div>
        <div class="margin-left4 flex flex-center">
          <el-popover
            placement="bottom"
            width="72"
            trigger="hover"
            class="text-align-center fontSize14"
          >
            <img
              class="QR-code cursor-pointer"
              slot="reference"
              src="@/assets/home/ubeiOffic.jpg"
              alt="公众号"
            />
            <div>
              <p>扫描二维码关注“有备智联科技”公众号</p>
              <img
                class="width100"
                src="@/assets/home/ubeiOffic.jpg"
                alt="公众号"
              />
              <p>可在电脑上打开有备平台,并通过公众号推送离线消息</p>
            </div>
          </el-popover>
          <div class="fontSize12 margin-left1">
            <p>关注公众号</p>
            <p>在手机上查询</p>
            <p
              @click="download"
              class="background-color27 textColor10 border-radius4 padding1 cursor-pointer actives05"
            >
              下载有备助手
            </p>
          </div>
        </div>
      </div>
      <div
        class="content-box margin-auto flex flex-center margin-top6 fontSize16 font-weight textColor03"
      >
        <div
          @click="redireact('/home/index')"
          class="margin-right6 cursor-pointer actives05"
          :class="currentView === 0 ? 'textColor23' : ''"
        >
          首页
        </div>
        <div
          @click="redireact('/home/postEnquiry')"
          class="margin-right6 cursor-pointer actives05"
          :class="currentView === 2 ? 'text-warning' : ''"
        >
          发布询价
        </div>
        <div
          @click="redireact('/home/yellowPage')"
          class="margin-right6 cursor-pointer actives05"
        >
          汽配黄页
        </div>
        <div
          @click="redireact('/home/alliancePromotion')"
          class="margin-right6 cursor-pointer actives05"
          :class="currentView === 4 ? 'text-warning' : ''"
        >
          联盟推广
        </div>
        <div
          @click="redireact('/home/needZone')"
          class="margin-right6 cursor-pointer actives05"
          :class="currentView === 1 ? 'textColor23' : ''"
        >
          求购专区
        </div>
        <div
          @click="redireact('/home/noResult')"
          class="margin-right6 cursor-pointer actives05"
          :class="currentView === 3 ? 'text-warning' : ''"
        >
          查询未得
        </div>
        
        <div class="flex1"></div>
      </div>
      <div class="width100 background-color26 padding-bottom6 height100">
        <div v-if="currentView !== 0" class="padding2 margin-top3"></div>
        <div
          class="content-box margin-auto background-color10"
          :class="currentView === 0 ? 'margin-top3' : ''"
        >
          <router-view />
          <!-- <homepage v-show="currentView === 0" />
          <needZone v-show="currentView === 1" />
          <postEnquiry v-show="currentView === 2" :enquiryCode="enquiryCode" />
          <noResult v-show="currentView === 3" /> -->
        </div>
      </div>
      <fixedNav :showFirst="true" />
    </div>
  </div>
</template>
<script>
import fixedNav from "@/components/fixedNav/fixedNav";
// import homepage from "./homepage.vue";
// import needZone from "./needZone.vue";
// import postEnquiry from "./postEnquiry.vue";
// import noResult from "./noResult.vue";
import { dynamicListApi } from "@/api/home/log";
import { STATUS } from "@/store/index";
export default {
  components: {
    fixedNav,
    // homepage,
    // needZone,
    // postEnquiry,
    // noResult,
  },
  props: {
    currentView: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      keyword: "",
      dynamicList: [],
      timer: null,
      enquiryCode: null,
    };
  },
  mounted() {
    this.init();
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      const scroller = document.getElementById("scroller");
      const scrollerWrapper = document.getElementById("scrollerWrapper");
      if (scroller && scrollerWrapper) {
        if (scrollerWrapper.scrollTop >= scroller.scrollHeight - 60) {
          scrollerWrapper.scrollTop = 0;
        } else {
          scrollerWrapper.scrollTop++;
        }
      }
    }, 100);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    init() {
      const data = {
        objectType: "",
        objectId: 0,
      };
      dynamicListApi(data).then((res) => {
        this.dynamicList = [...res];
      });
    },
    download() {
      const linkDom = document.createElement("a");
      linkDom.href = "https://threedl.threesoft.cn/three/ubei/UbeiSetup.EXE";
      linkDom.setAttribute("download", "有备助手");
      linkDom.click();
    },
    gotoSearch() {
      this.$router.push(`/home/search?keyword=${this.keyword}`);
    },
    setView(view) {
      if (view == 2) {
        if (!this.$store.state.profile.id) {
          this.$router.push("/login");
          return;
        }
      }
      this.currentView = view;
    },
    redirect(url) {
      this.$router.push(url);
    },
    redireact(path) {
      if (path === "/home/postEnquiry" && !this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      this.$router.push(path);
    },
    auth() {
      this.$store.dispatch("changeAuthStatus", STATUS.AUTH);
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
#scrollerWrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#scrollerWrapper::-webkit-scrollbar {
  display: none;
}

.page-home-index .warning-box {
  border: 1px solid #ff6e4c;
  background-color: #ffeeeb;
}

.page-home-index .warning-box img {
  width: 16px;
  height: 16px;
}

.page-home-index .logo-img {
  height: 64px;
}

.page-home-index .search-img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.page-home-index .search-box {
  border: 3px solid #ff6e4c;
}

.page-home-index .search-box-input {
  border: none;
  outline: none;
}

.page-home-index .QR-code {
  width: 72px;
  height: 72px;
}
</style>
