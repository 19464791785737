<template>
  <div class="width100 page-box">
    <Header class="position-fixed top0 z-index3001" />
    <div>
      <HomeTemplate v-if="isRouterHome" :currentView="currentView" />
      <router-view v-else />
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../userCenter/components/Header.vue";
import Footer from "./components/footer";
import HomeTemplate from "./index/index.vue";

export default {
  components: {
    Header,
    Footer,
    HomeTemplate,
  },
  data() {
    return {
      isRouterHome: true,
      currentView: null,
    };
  },
  watch: {
    $route(val) {
      this.isRouterHome = val.meta.routerhome || false;
      this.currentView = val.meta.currentView;
      // console.log("isRouterHome", this.isRouterHome);
      // console.log("currentView", this.currentView);
    },
  },
  mounted() {
    this.isRouterHome = this.$route.meta.routerhome || false;
    this.currentView = this.$route.meta.currentView;
    // console.log("isRouterHome", this.isRouterHome);
    // console.log("currentView", this.currentView);
  }
};
</script>
<style>
.page-box {
  padding-top: 32px;
}
</style>
